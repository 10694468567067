
import Vue from "vue";
import { UserRegister } from "@/models/userregister";
import { SaveRes } from "@/models/saveres";
import { Snackbar } from "@/models/snackbar";
import { ComboBox } from "@/models/combobox";
import router from "@/router";
import i18n from "@/i18n";

export default Vue.extend({
  data: () => ({
    isFormValid: false,
    isRegisterSuccess: false,
    isRegisterLoading: false,
    lang: [
      { title: "En", value: "en" },
      { title: "Fr", value: "fr" },
      { title: "Es", value: "es" },
    ],
    tempUserRegister: {
      email: "",
      organization: "",
      organizationId: null,
      organizationName: "",
      organizationCity: "",
      organizationCountryId: null,
      firstName: "",
      lastName: "",
      phone: "",
      roleInOrganization: "",
    } as UserRegister,
    rules: {
      required: [(v: string) => !!v || i18n.t("rules.required")],
      name: [
        (v: string) => !!v || i18n.t("rules.required"),
        (v: string) => (v && v.length >= 3) || i18n.t("rules.morethan3char"),
      ],
      email: [
        (v: string) => !!v || i18n.t("rules.required"),
        (v: string) => /.+@.+\..+/.test(v) || i18n.t("rules.emailmustbevalid"),
      ],
      select: [(v: string) => !!v || i18n.t("rules.required")],
    },
  }),
  computed: {
    organizationRule() {
      return [
        (v: string | ComboBox) => !!v || i18n.t("rules.required"),
        (v: unknown) =>
          typeof v !== "string" ||
          (v && (v as string).length >= 2) ||
          i18n.t("rules.morethan2char"),
      ];
    },
    userRegisterRes() {
      return this.$store.getters["user/getUserRegisterRes"];
    },
    countries() {
      let countries = this.$store.getters["datamanager/getCountries"];
      return countries
        .slice()
        .sort(
          (
            a: { name_en: string; name_fr: string; name_es: string },
            b: { name_en: string; name_fr: string; name_es: string }
          ) => {
            if (i18n.locale == "en") return a.name_en.localeCompare(b.name_en);
            else if (i18n.locale == "fr")
              return a.name_fr.localeCompare(b.name_fr);
            else if (i18n.locale == "es")
              return a.name_es.localeCompare(b.name_es);
          }
        );
    },
    organizations() {
      return this.$store.getters["user/getOrganization"];
    },
    langsel() {
      return i18n ? i18n.locale : "en";
    },
  },
  methods: {
    goHome() {
      router.push("/");
    },
    triggerSnack(snack: Snackbar) {
      this.$store.dispatch("general/changeSnackbar", snack);
    },
    register() {
      console.log(this.tempUserRegister.organization);
      if (typeof this.tempUserRegister.organization === "string") {
        this.tempUserRegister.organizationId = null;
        this.tempUserRegister.organizationName =
          this.tempUserRegister.organization;
      } else {
        this.tempUserRegister.organizationId = (
          this.tempUserRegister.organization as ComboBox
        ).id;
        this.tempUserRegister.organizationName = (
          this.tempUserRegister.organization as ComboBox
        ).name;
      }

      this.isFormValid = (
        this.$refs.form as Vue & {
          validate: () => boolean;
        }
      ).validate();

      if (this.isFormValid) {
        let res = this.userRegister(this.tempUserRegister);
        this.registerRes(res).then((e) => {
          if (e == 200) {
            this.isRegisterSuccess = true;
          }
        });
      }
    },
    registerRes(res: Promise<SaveRes>) {
      return res
        .then((e) => {
          if (e.status == 200) {
            let snack = {
              isSnacking: true,
              snackColor: "success",
              snackMessage: this.$t("basic.savesuccess").toString(),
              snackTimeout: "2000",
            };
            this.triggerSnack(snack);
          }
          return e.status as Number;
        })
        .catch((err) => {
          if (err.response.status == 422) {
            let snack = {
              isSnacking: true,
              snackColor: "orange",
              snackMessage:
                this.$t("basic.savewarning").toString() +
                err.response.statusText,
              snackTimeout: "2000",
            };
            this.triggerSnack(snack);
          } else {
            let snack = {
              isSnacking: true,
              snackColor: "error",
              snackMessage:
                this.$t("basic.saveerror").toString() + err.response.statusText,
              snackTimeout: "2000",
            };
            this.triggerSnack(snack);
          }
          return err.response.status as Number;
        });
    },
    async userRegister(user: UserRegister) {
      this.isRegisterLoading = true;

      await this.$store.dispatch("user/userRegister", {
        culture: i18n.locale,
        data: user,
      });
      this.isRegisterLoading = false;
      return this.userRegisterRes as SaveRes;
    },
    async fetchCountries() {
      await this.$store.dispatch("datamanager/fetchCountries");
    },
    async fetchOrganizations() {
      console.log(this.tempUserRegister);
      await this.$store
        .dispatch(
          "user/fetchOrganizations",
          this.tempUserRegister.organizationCountryId
        )
        .then((this.tempUserRegister.organization = null));
    },
  },
  created() {
    this.fetchCountries();
  },
});
